import React from 'react';
import {convertPrice, getDiscountValueWithNotAppleySum, secondsToMinutes} from "../../utils/helper.service";
import {useTranslation} from "gatsby-plugin-react-i18next";

const TimeSelect = (props) => {
    const {t} = useTranslation();
    return (


        <div className="panel box">


            {   props.showPleaseSelectVehicle ? null :    <div className={'notification is-success'}>
                <p className={'is-size-4'}>{t('total')}:

                    <span><b>{getDiscountValueWithNotAppleySum(props.totalPriceFromServer, props.customer.discount_in_percents, props.totalPriceDiscountsNotappleyFromServer,)}</b></span>
                </p>
                <p>{t('total_time')}: <b>{secondsToMinutes(props.totalDuration)}</b> {t('minutes')}</p>
            </div>}

            <h3 className="mb-2 is-size-4">
                {t('times_available')}:
            </h3>


            {props.selectedDateAvailableDates.length > 0 ? props.selectedDateAvailableDates.map(selectedDate => (

                <div key={selectedDate.value} className="panel-block columns">

                    <button  value={selectedDate.value}  onClick={(e) => props.bookTime(e.target.value)}  type="button" className="button is-link column is-full">{t('book_now')} (  {selectedDate.label})</button>

                </div>

            )) :

            props.showPleaseSelectVehicle ? <div className={'notification is-warning'}>
                {t('booking_notification_times_after_select_vehicle')}
            </div> : <div className={'notification is-warning'}>
                {t('booking_no_results')}
            </div>
            }


        </div>
    );

};

export default TimeSelect;

/*
        <span className={'is-size-7 ml-1'}  style={{
                        textDecoration: `line-through`,
                    }}><b>{convertPrice(props.totalPriceFromServer)}</b> </span>
 */