import React from "react";
import DatePicker from "react-datepicker";
import {useTranslation} from "gatsby-plugin-react-i18next";
const BookingForm = (props) => {
    const {t} = useTranslation();
    return (

        <div className="box mb-4">
            
            <div className="field">
                <label className="label">{t('date')}</label>
                <DatePicker
                    className="input"
                    dateFormat="dd/MM/yyyy"
                    selected={props.selectedDate}
                    minDate={new Date()}
                    onChange={(date) => props.handleChangeDate(date)

                    }
                />
            </div>

            <div className="field">
                <label className="label">{t('address')}</label>

                {props.needAddAddress ? null :      <div className="select">
                    <select name={'address'} onChange={(e) => props.handleChangeAddress(e.target.value)} >
                        {props.addresses.map(address => (
                            <option value={address.id}  key={address.id}>{address.formatted_address}</option>
                        ))}

                    </select>
                </div>}


            </div>



        </div>


    );

};

export default BookingForm;
/*
<Link to="/addresses">Pievienojiet visamaz vienu adresi</Link></p>
 */
