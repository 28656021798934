import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import PhoneInput from "react-phone-number-input"

const BookingFormCustomContacts = props => {
  const { t } = useTranslation()

  const handleSwitchChange = event => {
    props.setCustomContactDetails(event.target.checked)
  }

  return (
    <div className="box mb-4">
      <div className="box-header">
        <div className="field">
          <div className="is-flex align-items-center">
            <input
              id="switchCustomContactDetails"
              type="checkbox"
              name="switchCustomContactDetails"
              className="switch is-rounded"
              checked={props.customContactDetails}
              onChange={handleSwitchChange}
            />
            <label htmlFor="switchCustomContactDetails" className="ml-2">
              {t("vehicle_owner_contact_info_switch_label")}
            </label>
          </div>
        </div>
      </div>

      {props.customContactDetails && ( // Conditional rendering
        <div className="box-content custom-data">
          <div className="notification is-info mt-2">
            <p>
            {t("vehicle_owner_contact_info_text")}
            </p>
          </div>

          <div className={"columns"}>
            <div className={"column"}>
              <div className="field">
                <label className="label">{t("person_name")}</label>
                <div className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    value={props.customName}
                    onChange={e => props.setCustomName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className={"column"}>
              <div className="field">
                <div className="control">
                  <label className="label">{t("phone")}*</label>
                  <PhoneInput
                    className="input"
                    placeholder={t("enter_phone_number")}
                    value={props.inputPhone}
                    defaultCountry="LV"
                    onChange={props.setInputPhone}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BookingFormCustomContacts
