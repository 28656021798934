import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
const PopupBooking = (props) => {

    const {t} = useTranslation();

    return (

    <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content box" >
            <p className={'mb-2'}>{t('accept_booking')}</p>
            <div className={'buttons'}>
                <button onClick={props.handleBookingFalse}  className="button is-danger">{t('cancel')}</button>
                <button onClick={props.handleBookingTrue} className="button is-success">
                    {t('accept')}
                </button>
            </div>
        </div>

    </div>

    );
}

export default PopupBooking;
