import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import TimeSelect from "../components/booking/time-select"
import BookingForm from "../components/booking/booking-form"
import { useEffect, useState } from "react"
import authHeader from "../utils/auth-header"
import { isLoggedIn } from "../utils/auth.service"
import { graphql, Link, navigate } from "gatsby"
import LoadingSpinner from "../components/loading-spinner"
import toast, { Toaster } from "react-hot-toast"
import VehicleBlock from "../components/booking/vehicleBlock"
import PopupBooking from "../components/popup-booking"
import { useTranslation } from "gatsby-plugin-react-i18next"
import BookingFormCustomContacts from "../components/booking/booking-form_custom-contacts"

const Booking = ({ location, fromFeed }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
  })

  const [showAddVehicleButton, setShowVehicleButton] = useState(false)

  const [selectedDateFormatted, setSelectedDateFormatted] = useState(
    new Date().toISOString().slice(0, 10).replace("T", " ")
  )
  const [selectedDate, setSelectedDate] = useState(new Date())

  const [vehicles, setVehicles] = useState([])
  const [userData, setUserData] = useState(false)
  const [addresses, setAddresses] = useState([])
  const [needAddAddress, setNeedAddAddress] = useState(1)
  const [totalDuration, setTotalDuration] = useState(0)
  // const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceFromServer, setTotalPriceFromServer] = useState(0)
  const [totalPriceDiscountsNotappleyFromServer, setTotalPriceDiscountsNotappleyFromServer] = useState(0)
  const [needAddVehicle, setNeedAddVehicle] = useState(1)
  const [services, setServices] = useState([])
  const [customer, setCustomer] = useState()
  const [allServices, setAllServices] = useState([])

  const [selectedVehicleID, setSelectedVehicleID] = useState("")
  const [showPleaseSelectVehicle, setShowPleaseSelectVehicle] = useState(true)
  const [selectedVehicle, setSelectedVehicle] = useState("")

  const [selectedVehicles, setSelectedVehicles] = useState([])

  const [selectedAddressID, setSelectedAddressID] = useState("")

  const [selectedDateAvailableDates, setSelectedDateAvailableDates] = useState(
    []
  )

  const [vehiclesBlocks, setVehiclesBlock] = useState([])
  const [vehicleSelectvalue, SetVehicleSelectvalue] = useState("default")

  const [vehicleCategories, setVehicleCategories] = useState([])

  //customer
  const [customContactDetails, setCustomContactDetails] = useState(false)
  const [inputPhone, setInputPhone] = useState("")
  const [customName, setCustomName] = useState("")

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"))
    setUserData(userData)
    localStorage.removeItem("temp_booking_vehicle") //
  }, [])

  const updateTotal = async () => {
    const bookingRecord = JSON.parse(
      localStorage.getItem("temp_booking_vehicle")
    )

    if (bookingRecord) {
      let totalDuration = 0
   
      bookingRecord.map(
        vehicle => (
          (totalDuration += parseInt(allServices[vehicle.service_id].duration)),
          vehicle.additional_services.length !== 0
            ? vehicle.additional_services.map(
                additionalServiceId =>
                  (totalDuration +=
                    allServices[vehicle.service_id].additional_services[
                      additionalServiceId
                    ].duration) //count vehicle additional servces
              )
            : null
        )
      )

      setTotalDuration(totalDuration)
    }
  }

  const handleAddVehicleBlock = async e => {
    e.preventDefault()

    SetVehicleSelectvalue("default") //Not need set

    if (selectedVehicleID === "") {
      toast.error("Izvēlaties transportlīdzekli!")
      return
    }

    let newlySettedServices = [
      ...services,
      {
        vehicle_id: selectedVehicleID,
        selected_service_id: 1,
        vehicle_services: allServices, //default 1 for allBlocks
      },
    ]

    setServices(newlySettedServices)

    setServices([
      ...services,
      {
        vehicle_id: selectedVehicleID,
        selected_service_id: 1,
        vehicle_services: allServices, //default 1 for allBlocks
      },
    ])

    let recordKey = vehicleCategories.findIndex(
      el => parseInt(el.id) === parseInt(selectedVehicle.category_id)
    )
    let selectedCategory = vehicleCategories[recordKey]

    setVehiclesBlock([
      ...vehiclesBlocks,
      <VehicleBlock
        selectedCategory={selectedCategory}
        customer={customer}
        selectedVehicle={selectedVehicle}
        vehicleId={selectedVehicleID}
        services={newlySettedServices}
        handleCheckAdditionalServicesNew={handleCheckAdditionalServicesNew}
        handleChangeServices={handleChangeServices}
        key={vehiclesBlocks.length}
      />,
    ])

    setSelectedVehicles([
      ...selectedVehicles,
      {
        id: selectedVehicleID,
        service_id: 1, //default 1 for allBlocks
      },
    ])

    if (typeof window !== "undefined") {
      const bookingRecord = JSON.parse(
        localStorage.getItem("temp_booking_vehicle")
      )

      //if exist record
      if (bookingRecord) {
        //add new to existing

        bookingRecord.push({
          id: selectedVehicleID,
          service_id: 1,
          additional_services: [],
        })

        localStorage.setItem(
          "temp_booking_vehicle",
          JSON.stringify(bookingRecord)
        )
      } else {
        //create new
        let defaultTempBookingDataVehicles = [
          {
            id: selectedVehicleID,
            service_id: 1,
            additional_services: [], //set default
          },
        ]
        localStorage.setItem(
          "temp_booking_vehicle",
          JSON.stringify(defaultTempBookingDataVehicles)
        )
      }
    }

    //remove from dropdown
    let filteredArray = vehicles.filter(item => item.id !== selectedVehicleID)
    setVehicles(filteredArray)

    //disable button if no more vwhicles
    if (filteredArray.length === 0) {
      setShowVehicleButton(false)
    }

    setSelectedVehicleID("")
    setSelectedVehicle("")

    setShowPleaseSelectVehicle(false)

    await updateTotal()
    await reloadTimes()
  }

  /*
    @todo to one function
     */
  useEffect(() => {
    if (!isLoggedIn()) {
      return navigate("/")
    }

    if (location) {
      if (location.state) {
        if (typeof location.state.fromFeed !== "undefined") {
          let stringDate = location.state.fromFeed
          let timestamp = Date.parse(stringDate)
          setSelectedDateFormatted(stringDate)
          setSelectedDate(new Date(timestamp)) //date object
        }
      }
    }

    loadDefaultFormDataFromServer()
    // loadVehicleCategories();
  }, [])

  const loadDefaultFormDataFromServer = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    // get data from GitHub api
    fetch(
      process.env.WASHCAR_API_URL + "/business/get-booking-form-data",
      requestOptions
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        if (resultData.data.customer_vehicles.length === 0) {
          return navigate("/vehicles")
        }

        if (resultData.data.customer_addresses.length === 0) {
          return navigate("/addresses")
        }

        setVehicles(resultData.data.customer_vehicles)
        setAddresses(resultData.data.customer_addresses)

        setVehicleCategories(
          resultData.data.vehicle_categories_with_bonus_prices
        ) ///remaked by single request

        setAllServices(resultData.data.services_with_additional_services)
        setCustomer(resultData.data.customer)

        if (resultData.data.customer_vehicles.length !== 0) {
          setNeedAddVehicle(0)
        }

        //set first address as selected
        if (resultData.data.customer_addresses.length !== 0) {
          setSelectedAddressID(resultData.data.customer_addresses[0].id)
          setNeedAddAddress(0)
        }

        setLoading(false)
      }) // set data for the number of stars
  }

  const reloadTimesNew = selectedDateFormattedPersonal => {
    try {
      setLoading(true)

      if (selectedAddressID === "") {
        toast.error("Pievienojiet vismaz vienu adresi!")
        setLoading(false)
        return
      }

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          address_id: selectedAddressID,
          selected_date: selectedDateFormattedPersonal,

          // vehicles: selectedVehicles,
          vehicles: JSON.parse(localStorage.getItem("temp_booking_vehicle")), // this is how you parse a string into JSON ,
        }),
      }

      fetch(
        process.env.WASHCAR_API_URL +
          "/business/get-selected-date-available-times",
        requestOptions
      )
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setLoading(false)
          setSelectedDateAvailableDates(resultData.data.available_times)
          setTotalPriceFromServer(resultData.data.total_sum)
          setTotalPriceDiscountsNotappleyFromServer(resultData.data.total_ammount_discount_not_apply)

       
          
        }) // set data for the number of stars
    } catch (err) {
      console.log(err)
    }
  }

  const reloadTimes = async e => {
    try {
      setLoading(true)

      if (selectedAddressID === "") {
        toast.error("Pievienojiet vismaz vienu adresi!")
        setLoading(false)
        return
      }

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          address_id: selectedAddressID,
          selected_date: selectedDateFormatted,

          // vehicles: selectedVehicles,
          vehicles: JSON.parse(localStorage.getItem("temp_booking_vehicle")), // this is how you parse a string into JSON ,
        }),
      }

      fetch(
        process.env.WASHCAR_API_URL +
          "/business/get-selected-date-available-times",
        requestOptions
      )
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setLoading(false)
          setSelectedDateAvailableDates(resultData.data.available_times)
          setTotalPriceFromServer(resultData.data.total_sum)
          setTotalPriceDiscountsNotappleyFromServer(resultData.data.total_ammount_discount_not_apply)
        }) // set data for the number of stars
    } catch (err) {
      console.log(err)
    }

    //
  }

  const handleChangeVehicle = async value => {
    // console.log(value)
    setSelectedVehicleID(value)

    const selectedVehicle = vehicles.find(x => x.id === value)
    setSelectedVehicle(selectedVehicle)

    SetVehicleSelectvalue(value) //Not need set//NEWWWWWWWWWWWWWWWWW
    setShowVehicleButton(true)
  }

  const handleChangeAddress = async value => {
    setSelectedAddressID(value)

    if (selectedVehicles.length !== 0) {
      await reloadTimes()
    }
  }

  const handleChangeServices = async (vehicleId, serviceId) => {
    //special for switchhh

    const ele = document.getElementsByClassName(
      "additional-services-" + vehicleId + "-" + serviceId
    )[0]
    const elVehicleAdditionalServices = document.getElementsByClassName(
      "as-for-vehicle-" + vehicleId
    )

    for (let i = 0; i < elVehicleAdditionalServices.length; i++) {
      elVehicleAdditionalServices.item(i).classList.add("is-hidden")
    }

    if (ele.classList.contains("is-hidden")) {
      ele.classList.remove("is-hidden")
    }
    ////////////

    setSelectedVehicles([
      ...selectedVehicles,
      {
        id: vehicleId,
        service_id: serviceId,
      },
    ])

    if (typeof window !== "undefined") {
      const bookingRecord = JSON.parse(
        localStorage.getItem("temp_booking_vehicle")
      )
      const selectedVehicle = bookingRecord.find(x => x.id === vehicleId)

      let recordKey = bookingRecord.findIndex(
        el => el.id === selectedVehicle.id
      )

      bookingRecord[recordKey].service_id = serviceId
      bookingRecord[recordKey] = selectedVehicle
      bookingRecord[recordKey].additional_services = [] ///reset addtional services
      localStorage.setItem(
        "temp_booking_vehicle",
        JSON.stringify(bookingRecord)
      )
    }

    updateTotal()
    await reloadTimes()
  }

  const handleCheckAdditionalServicesNew = async (
    vehicleId,
    serviceId,
    event
  ) => {
    if (typeof window !== "undefined") {
      const bookingRecord = JSON.parse(
        localStorage.getItem("temp_booking_vehicle")
      )
      if (!bookingRecord) {
        return
      }

      //bookingRecord
      const selectedVehicle = bookingRecord.find(x => x.id === vehicleId)
      let recordKey = bookingRecord.findIndex(
        el => el.id === selectedVehicle.id
      )

      let newArr = [...bookingRecord[recordKey].additional_services]

      if (event.target.checked) {
        newArr = [
          ...bookingRecord[recordKey].additional_services,
          event.target.value,
        ]
      } else {
        newArr.splice(
          bookingRecord[recordKey].additional_services.indexOf(
            event.target.value
          ),
          1
        )
      }

      bookingRecord[recordKey].additional_services = newArr

      localStorage.setItem(
        "temp_booking_vehicle",
        JSON.stringify(bookingRecord)
      )

      updateTotal()
      await reloadTimes()
    }
  }

  const handleChangeDate = value => {

    setSelectedDate(value)
    let formatted = new Date(value).toJSON().slice(0, 10).replace("T", " ")
    setSelectedDateFormatted(formatted)

    if (selectedVehicles.length !== 0) {
      reloadTimesNew(formatted)
      ///      reloadTimesNew(formatted, selectedServiceID);//@todo ONNN
    }
  }

  const bookTime = async timeSlotValue => {
    setPopup({
      show: true,
      timeSlotValue,
    })
  }

  const handleBookingTrue = () => {
    if (popup.show && popup.timeSlotValue) {

      setLoading(true)

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          address_id: selectedAddressID,
          date: selectedDateFormatted,
          time_slot: popup.timeSlotValue,
          //custom contact data
          custom_contact_details_on: customContactDetails,
          person_name: customName,
          phone_with_code: inputPhone,
          vehicles: JSON.parse(localStorage.getItem("temp_booking_vehicle")), // this is how you parse a string into JSON ,
        }),
      }

      fetch(process.env.WASHCAR_API_URL + "/business/book-time", requestOptions)
        .then(response => response.json()) //
        .then(resultData => {
          if (typeof window !== "undefined") {
            localStorage.removeItem("temp_booking_vehicle") //@todo remake remove booking req
          }

          //@todo by status
          if (resultData.status === "Success") {
            toast.success(resultData.alert.text)
            ///te
            //return navigate('/view-order/', { state: { orderId: resultData.data.order_id} });
            return navigate("/view-order?id=" + resultData.data.order_id)
          } else {
            setLoading(false)
            toast.error(resultData.data.text)
          }
        })

      setPopup({
        show: false,
        id: null,
      })
    }
  }

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleBookingFalse = () => {
    // console.log('handle false');

    setPopup({
      show: false,
      id: null,
    })
  }

  /*
    const loadVehicleCategories = () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };


        fetch(process.env.WASHCAR_API_URL + "/business/my-vehicles", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setLoading(false);
                setVehicleCategories(resultData.data.vehicle_categories);

            }) //
    }

 */

  return (
    <Layout>
      <Seo title={t("booking")} />

      <Toaster position="top-center" reverseOrder={false} />

      {popup.show ? (
        <PopupBooking
          handleBookingTrue={handleBookingTrue}
          handleBookingFalse={handleBookingFalse}
        />
      ) : null}

      <h1 className="title">{t("booking")}</h1>

      <div className={"columns"}>
        <div className={"column"}>
          <div className={"box notification is-warning"}>
            <p>{t("booking_notification_for_one_day_may_cars")}</p>
          </div>
        </div>
      </div>

      <form>
        <div className="columns">
          <div className="column">
            {loading ? (
              <div className="pos-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div>
                <BookingForm
                  needAddAddress={needAddAddress}
                  handleChangeDate={handleChangeDate}
                  selectedDate={selectedDate}
                  vehicles={vehicles}
                  addresses={addresses}
                  handleChangeAddress={handleChangeAddress}
                  handleChangeVehicleFunction={handleChangeVehicle}
                />
                <BookingFormCustomContacts
                  customContactDetails={customContactDetails}
                  setCustomContactDetails={setCustomContactDetails}
                  inputPhone={inputPhone}
                  setInputPhone={setInputPhone}
                  customName={customName}
                  setCustomName={setCustomName}
                />
              </div>
            )}

            <div className={"box"}>
              {needAddVehicle ? null : (
                <div className={""}>
                  <label className="label">{t("vehicles")}</label>

                  <div className={"notification"}>
                    <div className="field">
                      <label className="label"> {t("adding_to_booking")}</label>
                      <div className="control">
                        <div className="select">
                          <select
                            value={vehicleSelectvalue}
                            name={"vehicle"}
                            onChange={e => handleChangeVehicle(e.target.value)}
                          >
                            <option value={"default"} disabled>
                              {t("booking_choose_vehicle")}
                            </option>
                            {vehicles.map(vehicle => (
                              <option value={vehicle.id} key={vehicle.id}>
                                {vehicle.make_and_model +
                                  " - " +
                                  vehicle.reg_number}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {showAddVehicleButton ? (
                      <button
                        onClick={handleAddVehicleBlock}
                        className="button is-info"
                      >
                        <i className="fas fa-plus"></i> {t("add_to_booking")}
                      </button>
                    ) : null}
                  </div>
                </div>
              )}

              <div className={"mt-4"}>{vehiclesBlocks}</div>
            </div>
          </div>
          <div className="column">
            {loading ? (
              <div className="pos-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div>
                <TimeSelect
                  totalPriceFromServer={totalPriceFromServer}
                  totalPriceDiscountsNotappleyFromServer={totalPriceDiscountsNotappleyFromServer}
                  customer={customer}
                  totalDuration={totalDuration}
                  showPleaseSelectVehicle={showPleaseSelectVehicle}
                  bookTime={bookTime}
                  selectedDateAvailableDates={selectedDateAvailableDates}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default Booking

/*
     <div className={'column'}>
                    {userData ?    <div className={'box notification is-info'}>
                        <p>{t('booking_notification_for_discount')} <b>{userData.customer_discount_percent}%</b> {t('amount')}</p>
                    </div>  : null}
                </div>
 */

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
